/* Header.css */

/* Default styles for larger screens */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

.nav li {
  position: relative;
}

.nav li a {
  color: #034833; /* Dark green color for the links */
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav li a:hover {
  background-color: #78c000; /* Same color as the hero button */
  color: #fff;
}

.nav li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #78c000; /* Same color as the hero button */
  left: 0;
  bottom: -5px;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.nav li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .logo {
    font-size: 20px;
  }
}