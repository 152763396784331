/* Features.css */
.features-container {
  padding: 20px;
}

.features-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.features-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-item {
  background-color: #f9f9f9;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 30%;
  max-width: 30%;
  text-align: center;
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .features-container {
    padding: 10px;
  }

  .features-title {
    font-size: 1.5rem;
  }

  .features-list {
    flex-direction: column;
    align-items: center;
  }

  .feature-item {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 10px 0;
  }

  .feature-item h3 {
    font-size: 1.25rem;
  }

  .feature-item p {
    font-size: 0.875rem;
  }
}