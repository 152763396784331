.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Add this to your CSS file, e.g., App.css */

/* Default styles for larger screens */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  padding: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header, .footer, .promo-banner {
    text-align: center;
  }

  .features, .testimonials {
    margin: 0 auto;
    width: 100%;
  }
} 